import React from 'react';
import './ConditionsDisplay.scss'
import JSONPretty from 'react-json-pretty';

type conditionsDisplayPropType = {
    conditionsData: any
}

const ConditionsDisplay = ({ conditionsData }: conditionsDisplayPropType) => {

    return (
        <div className="conditions-display-component">
            {conditionsData && conditionsData['alternatives'].length > 0
                ? <JSONPretty id='ConditionsDisplay' data={conditionsData}></JSONPretty>
                : <div className='tds-u-ml2 tds-body-02'>No conditions data</div>}
        </div>

    )
}

export default ConditionsDisplay