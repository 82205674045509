import React from 'react';
import './SearchBar.scss'
import { TdsIcon } from '@scania/tegel-react';
import { debounce } from 'lodash';

type SearchBarProps = {
    setSearchedValue: Function,
}

const SearchBar = (props: SearchBarProps) => {
    const { setSearchedValue } = props;
    const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchedValue(event.target.value)
    }

    return (
        <div className='searchBar'>
            <input type='search' title={'Search'} placeholder={'Search'} onChange={debounce(handleChangeEvent,300)} />
            <TdsIcon className="searchIcon" slot="prefix" name="search" size="20px"></TdsIcon>
        </div>
    )
}

export default SearchBar;
