
import { modelDropdownList, persistDropdownList } from '../../../helpers/genericDropdownList';
import { observer } from 'mobx-react';
import GenericDropdown from '../../GenericDropdown/GenericDropdown';
import './Trigger.scss';
import TextField from '../../TextField/TextField';
import { useFormState } from 'react-final-form';

const Trigger = () => {
    const { values } = useFormState();
    const getValues = (dataFieldname: string) => {
        return values[dataFieldname];
    }

    return (
        <>
            <div className="tds-row tds-u-mt2">
                <div className="tds-col-sm-4 tds-col-xs-4">
                    <TextField
                        label="Unit"
                        size="md"
                        modeVariant='secondary'
                        label-position="outside"
                        value={getValues("unit")}
                        placeholder='Enter unit of measurement'
                        dataFieldName="unit"
                    />
                </div>
                <div className="tds-col-sm-4 tds-col-xs-4">
                    <GenericDropdown
                        label="Persist"
                        labelPosition="outside"
                        placeholder="Select a persist value"
                        size="md"
                        modeVariant="secondary"
                        defaultValue={getValues("persist")}
                        openDirection="down"
                        listData={persistDropdownList}
                        dataFieldName='persist'
                    />
                </div>
                <div className="tds-col-sm-4 tds-col-xs-4">
                    {/* //TODO: Currently, the API does not provide any data or at least an attribute. Once the API data is received, it needs to be implemented properly */}
                    <GenericDropdown
                        label="Mode *"
                        labelPosition="outside"
                        placeholder="Select mode"
                        size="md"
                        modeVariant="secondary"
                        openDirection="down"
                        defaultValue={getValues("mode")}
                        required={true}
                        listData={modelDropdownList}
                        dataFieldName='mode'
                    />
                </div>
            </div>
        </>
    );
};

export default observer(Trigger);