import { TdsAccordion, TdsAccordionItem, TdsDivider } from '@scania/tegel-react';
import { persistDropdownList } from '../../../helpers/genericDropdownList';
import { observer } from 'mobx-react';
import GenericDropdown from '../../GenericDropdown/GenericDropdown';
import './Gauge.scss';
import TextField from '../../TextField/TextField';
import ValueNamesEdit from '../../ValueNamesEdit/ValueNamesEdit';

const Gauge = () => {
    return (<>
        <div className='tds-row tds-u-mt3'>
            <div className='tds-col-sm-4'>
                <TextField
                    label="Unit *"
                    labelPosition="outside"
                    size="md"
                    required={true}
                    dataFieldName='unit'
                    placeholder="Enter the unit of measurement"
                    modeVariant='secondary'
                />
            </div>
            <div className='tds-col-sm-4'>
                <GenericDropdown
                    label="Persist *"
                    placeholder="Select persist value"
                    size="md"
                    labelPosition="outside"
                    modeVariant="secondary"
                    listData={persistDropdownList}
                    dataFieldName='persist'
                    required={true}
                />
            </div>
            {/* TODO: There is no key in the API response for value type right now. to be updated later */}
            <div className='tds-col-sm-4'>
                <TextField
                    label="Value type *"
                    size="md"
                    placeholder="Enter value type"
                    required={true}
                    label-position="outside"
                    modeVariant="secondary"
                    dataFieldName='value_type'
                />
            </div>
        </div>
        <TdsAccordion className="tds-u-mt3">
            <TdsAccordionItem header="Value names" expanded paddingReset >
                <ValueNamesEdit />
            </TdsAccordionItem>
            <div className="divider-width">
                <TdsDivider orientation="horizontal"></TdsDivider>
            </div>
        </TdsAccordion>
    </>
    );
};

export default observer(Gauge);