import React from 'react'
import { TdsButton, TdsIcon } from '@scania/tegel-react';
import { observer } from 'mobx-react';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import './ConditionsEdit.scss'
import TextField from '../../TextField/TextField';
import AlternativeEditBox from './AlternativeEditBox/AlternativeEditBox';


const ConditionsEdit = () => {
    const AlternativesItemKey = "alternatives"

    return <>
        <div className="tds-row tds-u-mt2 tds-u-mb2">
            <div className="tds-col-sm-12">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter description for the conditions "
                    label="Description"
                    labelPosition="outside"
                    dataFieldName="description"
                />
            </div>
        </div>
        <div className="tds-u-mt3">
            <h6 className="tds-headline-06">Alternatives *</h6>
        </div>
        <FieldArray name={AlternativesItemKey}>
            {({ fields }: FieldArrayRenderProps<any, HTMLElement>) => <>
                <div className='tds-u-flex-end'>
                    <TdsButton variant="primary" size="sm" text="Add" onClick={() => fields.push(null)}>
                        <TdsIcon slot="icon" size="20px" name="plus" />
                    </TdsButton>
                </div>
                {fields && fields.map((name, index) => {
                    return <AlternativeEditBox
                        key={"alternative-edit-box-" + index}
                        index={index}
                        removeFunction={() => { fields.remove(index) }}
                        name={name}
                    />
                })}
                {!fields && <div className='tds-container-fluid tds-u-p1'>
                    <div className='tds-row'>
                        <div className="tds-col-md-12 tds-u-mt1">
                            Click the add button to start adding Alternatives.
                        </div>
                    </div>
                </div>}
            </>}
        </FieldArray>
    </>
}

export default observer(ConditionsEdit)