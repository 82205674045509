import { ChainItemTypeEnum } from "../../../../helpers/dataHelper";
import GenericDropdown from "../../../GenericDropdown/GenericDropdown";

interface TypeDropdownPropType {
  selectedValue?: any,
  onItemTypeChange?: (newType: ChainItemTypeEnum) => void
  dataFieldName: string
}

const TypeDropDown = ({ dataFieldName }: TypeDropdownPropType) => {
  const fieldName = `${dataFieldName}.type`;

  const listData = Object.entries(ChainItemTypeEnum).map(([key, value]) => {
    return { name: key, value: value }
  })

  return <>
    <GenericDropdown
      className="variable-type-dropdown"
      label="Type *"
      labelPosition="outside"
      placeholder="Select type"
      size="md"
      modeVariant="secondary"
      openDirection="down"
      listData={listData}
      dataFieldName={fieldName}
      filter={true}
    />
  </>
}

export default TypeDropDown