import { TdsBanner, TdsSpinner } from "@scania/tegel-react";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import ConfirmationDialog from "../../components/VariableDetails/ConfirmationDialog/ConfirmationDialog";
import { VARIABLE_ACTIONS } from "../../helpers/constants";
import { useMobXStore } from "../../hooks/useMobXStore";
import "./BaseVariableView.scss";
import GenericFieldsView from "../../components/VariableDetailsView/GenericFieldsView/GenericFieldsView";
import TopSection from "../../components/BaseVariable/TopSection/TopSection"

const BaseVariableView = () => {
  let { id } = useParams();
  const { variableBaseStore } = useMobXStore()
  const { selectedVariable, selectedVariableBase, isLoadingVariableDetails, variableFetchError } = variableBaseStore
  const navigate = useNavigate();

  const clearSelectedVariable = () => {
    variableBaseStore.clearSelectVariable();
    navigate("/", { replace: true })
  }

  const navigateToVariableEditpage = () => {
    navigate(`/base/${selectedVariable}/edit`, { replace: false })
  }

  const navigateToAddVariantPage = () => {
    navigate(`/${selectedVariable}/edit`, { replace: false })
  }

  const detectUserAction = (userAction: string) => {
    if (VARIABLE_ACTIONS.EDIT === userAction) {
      navigateToVariableEditpage();
    }
    else if (VARIABLE_ACTIONS.ADD_VARIANT === userAction) {
      //TODO:  Add variable Variant user flow trigger
      navigateToAddVariantPage();
    }

  };
  useEffect(() => {
    if (id && parseFloat(id) !== variableBaseStore.selectedVariable) {
      variableBaseStore.selectVariableForViewing(parseFloat(id));
    }
  }, [id, variableBaseStore])


  return (
    <>
      {!isLoadingVariableDetails && variableFetchError !== "" &&
        <TdsBanner variant="error" header={variableFetchError} subheader={`The variable with Id: ${selectedVariable} not found in the database.`}>
        </TdsBanner>
      }
      {(selectedVariable >= 0 && !variableFetchError) &&
        <>
          {isLoadingVariableDetails ? <TdsSpinner size="md" /> :
            <>
              <TopSection
                selectedVariableId={selectedVariable}
                selectedVariableDetails={selectedVariableBase}
                clearSelectedVariable={clearSelectedVariable}
                onUserAction={detectUserAction}
              />
              <div className={`tds-container-fluid tds-background-grey-50 tds-u-p1 tds-u-pt2 tds-u-mt1 tds-u-pb3 form-container read`}>
                <GenericFieldsView selectedVariableBaseDetails={selectedVariableBase} />
              </div>
            </>
          }

        </>
      }
    </>
  );
};

export default observer(BaseVariableView);
