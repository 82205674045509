import { useContext } from "react"
import { TdsTextarea, TdsTextField } from "@scania/tegel-react"
import "./GenericFieldsView.scss"
import { UserActionContext } from "../../../AppContext"
import { getDisplayValueForTextFields } from "../../../helpers/getDisplayValue"
import { observer } from "mobx-react"

type GenericFieldsViewProps = {
    selectedVariableBaseDetails?: VariableBase
}

const GenericFieldsView = ({ selectedVariableBaseDetails }: GenericFieldsViewProps) => {
    const { action } = useContext(UserActionContext);

    return <>
        <div className='tds-row'>
            <div className="tds-col-sm-3">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Variable type *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.type, action)}
                >
                </TdsTextField>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    label="Variable name *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.name, action)}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter current status name for the variable"
                    label="Current status name"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.current_status_name, action)}
                />
            </div>
            <div className="tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter RFMS name for the variable"
                    label="RFMS name"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.rfms_name, action)} />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">
                <TdsTextarea
                    state="default"
                    rows={2}
                    modeVariant="secondary"
                    placeholder="Enter a description for the variable you are creating"
                    label="Description"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.description, action)}
                >
                </TdsTextarea>
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-3">
                <TdsTextField
                    state="default"
                    size="md"
                    modeVariant="secondary"
                    placeholder=""
                    label="In scope of Data act *"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(selectedVariableBaseDetails?.data_act, action)}
                >
                </TdsTextField>
            </div>
        </div>
    </>
}

export default observer(GenericFieldsView)