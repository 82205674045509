import React, {  } from 'react';
import { TdsButton, TdsIcon, } from '@scania/tegel-react';
import ChainComponent from './ChainComponent';
import { observer } from 'mobx-react';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import { useForm } from 'react-final-form';
import { ChainItemTypeEnum } from '../../../helpers/dataHelper';

const ChainEdit = () => {
  const dataFieldName = "chain"
  const form = useForm();
 
  const onChainItemTypeChange = (newChainItemType:ChainItemTypeEnum, index:number) => {;
    form.change(`${dataFieldName}[${index}].type`,newChainItemType);
  }

  return (<>
    <FieldArray name={dataFieldName}>
      {({ fields }: FieldArrayRenderProps<any, HTMLElement>) => <>
        <div className='tds-u-flex-end'>
          <TdsButton className='' text='Add' size='sm' onClick={() => fields.push({type:""})}>
            <TdsIcon slot="icon" size="20px" name="plus" />
          </TdsButton>
        </div>
        {
          fields.map((name, index) => (
            <ChainComponent name={name}
              index={index}
              key={`${name}-${index}`}
              chainItem={fields.value[index]}
              onRemoveChainItemClick={() => fields.remove(index)}
              onItemTypeChange={(newValue) => onChainItemTypeChange(newValue, index)}
            />
          ))
        }
        <div className='tds-container-fluid tds-u-p1'>
          <div className='tds-row'>
            {(!fields || fields.length === 0) && <div className="tds-col-md-12 tds-u-mt1">
              Click the add button to start adding Chain variables.
            </div>}
          </div>
        </div>
      </>}
    </FieldArray>
  </>
  );
}

export default observer(ChainEdit)