import React from 'react'
import './ChainDisplay.scss';
import JSONPretty from 'react-json-pretty';

type chainDisplayPropType = {
  chainData: ChainDataType
}

const ChainDisplay = ({ chainData }: chainDisplayPropType) => {

  return (
    <div className="chain-display-component">
      {chainData && chainData.length > 0
        ? <JSONPretty id='ChainDisplay' data={chainData}></JSONPretty>
        : <div className='tds-u-ml2 tds-body-02'>No chain data</div>}
    </div>

  )
}

export default ChainDisplay