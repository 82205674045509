import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import TypeDropDown from './TypeDropDown';
import { ChainEditPropType } from './types';
import TextField from '../../../TextField/TextField';

const TypeTrailerIdentification = ({ chainItem, onRemoveChainItemClick, onItemTypeChange, onAttributeValueChange, index, name}: ChainEditPropType) => {
  return <div className='tds-container-fluid tds-u-p1'>
    <div className='tds-row'>
      <div className="tds-col-md-12 tds-u-mt1 tds-u-flex-end">
        <TdsButton text='Remove' variant='danger' size='xs' onClick={onRemoveChainItemClick} />
      </div>
    </div>
    <div className="tds-row ">
      <div className="tds-col-md-3">
        <TypeDropDown onItemTypeChange={onItemTypeChange} dataFieldName={name}  />
      </div>
      <div className="tds-col-md-3">
        <TextField
          label='Length'
          value={chainItem?.length}
          labelPosition="outside"
          dataFieldName={`${name}.length`}
        />
      </div>
      <div className="tds-col-md-6">
        <TextField
          label='Source *'
          value={chainItem?.source}
          labelPosition="outside"
          dataFieldName={`${name}.source`}
          required={true}
        />
      </div>
    </div>
  </div>;
}

export default TypeTrailerIdentification