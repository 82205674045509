import React from 'react'
import './TestEditBox.scss'
import { TdsButton } from '@scania/tegel-react';
import TextField from '../../../TextField/TextField';

type TestEditBoxPropsType = {
    index: number,
    removeFunction: Function,
    name: string
}

const TestEditBox = ({ index, removeFunction, name }: TestEditBoxPropsType) => {

    return (
        <div className="tds-background-grey-400 tds-u-mt2 tds-u-pb2">
            <div className='tds-container-fluid tds-u-p1'>
                <div className='tds-row'>
                    <div className="tds-col-sm-12 tds-u-mt1 tds-u-flex-end">
                        <TdsButton variant="danger" size="xs" text="Remove" onClick={() => removeFunction(index)} />
                    </div>
                </div>
                <div className="tds-row tds-u-mt2">
                    <div className="tds-col-sm-4">
                        <TextField
                            label='Test *'
                            labelPosition='outside'
                            dataFieldName="Test.test"
                            required={true}
                            placeholder="Enter test"
                            size='md'
                        />
                    </div>
                    <div className="tds-col-sm-4">
                        <TextField
                            label='Type *'
                            labelPosition='outside'
                            dataFieldName="Test.type"
                            required={true}
                            placeholder="Enter type"
                            size='md'
                        />
                    </div>
                    <div className="tds-col-sm-4">
                        <TextField
                            label='Instance *'
                            labelPosition='outside'
                            dataFieldName="Test.instance"
                            required={true}
                            placeholder="Enter instance"
                            size='md'
                        />
                    </div>
                </div>

                <div className="tds-row tds-u-mt2">
                    <div className="tds-col-sm-12">
                        <TextField
                            type="text"
                            size="md"
                            modeVariant="secondary"
                            placeholder="Enter description for the test "
                            label="Description"
                            labelPosition="outside"
                            dataFieldName="Test.description"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TestEditBox