import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import TypeDropDown from './TypeDropDown';
import { ChainEditPropType } from './types';
import TextField from '../../../TextField/TextField';

const TypeCast = ({ chainItem, onRemoveChainItemClick, onItemTypeChange, onAttributeValueChange, index,name }: ChainEditPropType) => {
  return <div className='tds-container-fluid tds-u-p1'>
    <div className='tds-row'>
      <div className="tds-col-md-12 tds-u-mt1 tds-u-flex-end">
        <TdsButton text='Remove' variant='danger' size='xs' onClick={onRemoveChainItemClick} />
      </div>
    </div>
    <div className="tds-row ">
      <div className="tds-col-md-3">
        <TypeDropDown onItemTypeChange={onItemTypeChange} dataFieldName={name}  />
      </div>
      <div className="tds-col-md-3">
        <TextField 
          label='Type cast' 
          value={chainItem?.type_cast} 
          labelPosition="outside" 
          dataFieldName={`${name}.type_cast`}
        />
      </div>
      <div className="tds-col-md-6">
        <TextField 
          label='Value type' 
          value={chainItem?.value_type} 
          labelPosition="outside" 
          dataFieldName={`${name}.value_type`}
        />
      </div>
    </div>
  </div>;
}
export default TypeCast;