import React from "react"
import { TdsTextField } from "@scania/tegel-react"
import { useMobXStore } from "../../hooks/useMobXStore"
import { observer } from "mobx-react"
import TextField from "../TextField/TextField"
import TextArea from "../TextArea/TextArea"

// type BaseVariableFieldsProps = {
// }

const BaseEditComponent = () => {
    const { variableDetailsStore } = useMobXStore()
    const { selectedVariableDetails } = variableDetailsStore;

    //   useEffect(() => {
    //       const selectedType = getValues("type") as VariableType;
    //       setValue('scope', scopeValues[selectedType]);
    //       if (mode !== 'create') setValue('id', selectedVariableDetails?.id);
    //       // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [mode]);

    //   useEffect(() => {
    //       if (["create"].includes(mode)) {
    //           setValue("scope", scopeValues[values.type as VariableType])
    //       }
    //       // eslint-disable-next-line react-hooks/exhaustive-deps
    //   }, [values.type])

    return <>
        <div className='tds-row'>
            <div className="tds-col-sm-3">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    label="Variable type *"
                    labelPosition="outside"
                    readOnly={true}
                    value={selectedVariableDetails?.type}                    >
                </TdsTextField>


            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter a name "
                    label="Variable name *"
                    labelPosition="outside"
                    readOnly={true}
                    dataFieldName="name"
                    required={true}
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter current status name for the variable"
                    label="Current status name"
                    labelPosition="outside"
                    dataFieldName="current_status_name"
                />
            </div>
            <div className="tds-col-sm-6">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter RFMS name for the variable"
                    label="RFMS name"
                    labelPosition="outside"
                    dataFieldName="rfms_name" />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-12">

                <TextArea dataFieldName="description"
                    state="default"
                    rows={2}
                    modeVariant="secondary"
                    placeholder="Enter a description for the variable you are creating"
                    label="Description"
                    labelPosition="outside"
                />
            </div>
        </div>
        <div className="tds-row tds-u-mt3">
            <div className="tds-col-sm-3">
                <TextField
                    state="default"
                    size="md"
                    modeVariant="secondary"
                    placeholder=""
                    label="In scope of Data act *"
                    labelPosition="outside"
                    dataFieldName="inScope"
                />
            </div>
        </div>
    </>
}

export default observer(BaseEditComponent)