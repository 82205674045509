import './VariableDetailsView.scss'
import TopSection from '../TopSection/TopSection'
import { observer } from 'mobx-react'
import Spinner from '../Spinner/Spinner'
import FormSectionView from './FormSectionView/FormSectionView'


type VariableDetailsViewProps = {
  selectedVariableBaseDetails: VariableBase
  clearSelectedVariable: () => void
  isLoadingVariableDetails?: boolean
  detectUserAction: (userAction: string) => void
}

const VariableDetailsView = ({ selectedVariableBaseDetails, clearSelectedVariable, isLoadingVariableDetails, detectUserAction }: VariableDetailsViewProps) => {

  return (
    <>
      {isLoadingVariableDetails ? <Spinner /> :
        <>
          <TopSection
            selectedVariableBaseDetails={selectedVariableBaseDetails}
            clearSelectedVariable={clearSelectedVariable}
            onUserAction={detectUserAction}
          />
          <FormSectionView
            selectedVariableBaseDetails={selectedVariableBaseDetails}
          />
        </>
      }
    </>
  )
}

export default observer(VariableDetailsView)
