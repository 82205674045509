import React from 'react'
import './AlternativesEditBox.scss'
import { TdsButton, TdsIcon } from '@scania/tegel-react';
import TextField from '../../../TextField/TextField';
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays';
import TestEditBox from '../TestEditBox/TestEditBox';

type AlternativeEditBoxPropsType = {
    index: number,
    removeFunction: Function,
    name: string
}

const AlternativeEditBox = ({ index, removeFunction, name }: AlternativeEditBoxPropsType) => {
    const TestsItemKey = "tests"
    return (
        <div className="tds-background-grey-200 tds-u-mt2 tds-u-pb2">
            <div className='tds-container-fluid tds-u-p1'>
                <div className='tds-row'>
                    <div className="tds-col-sm-12 tds-u-mt1 tds-u-flex-end">
                        <TdsButton variant="danger" size="xs" text="Remove" onClick={() => removeFunction(index)} />
                    </div>
                </div>

                <div className="tds-row tds-u-mt2 tds-u-mb2">
                    <div className="tds-col-sm-12">
                        <TextField
                            type="text"
                            size="md"
                            modeVariant="secondary"
                            placeholder="Enter description for the alternatives "
                            label="Description"
                            labelPosition="outside"
                            dataFieldName="description"
                        />
                    </div>
                </div>
                <div className="tds-u-mt3">
                <h6 className="tds-headline-06">Tests *</h6>
                </div>
                <FieldArray name={TestsItemKey}>
                    {({ fields }: FieldArrayRenderProps<any, HTMLElement>) => <>
                        <div className='tds-u-flex-end'>
                            <TdsButton variant="primary" size="sm" text="Add" onClick={() => fields.push(null)}>
                                <TdsIcon slot="icon" size="20px" name="plus" />
                            </TdsButton>
                        </div>
                        {fields && fields.map((name, index) => {
                            return <TestEditBox
                                key={"test-edit-box-" + index}
                                index={index}
                                removeFunction={() => { fields.remove(index) }}
                                name={name}
                            />
                        })}
                        {!fields && <div className='tds-container-fluid tds-u-p1'>
                            <div className='tds-row'>
                                <div className="tds-col-md-12 tds-u-mt1">
                                    Click the add button to start adding Alternatives.
                                </div>
                            </div>
                        </div>}
                    </>}
                </FieldArray>
            </div >
        </div >
    )
}

export default AlternativeEditBox