import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import TypeDropDown from './TypeDropDown';
import { ChainEditPropType } from './types';
import TextField from '../../../TextField/TextField';

const TypeDyadic = ({ chainItem, onRemoveChainItemClick, onItemTypeChange, onAttributeValueChange, index, name }: ChainEditPropType) => {
  return <div className='tds-container-fluid tds-u-p1'>
    <div className='tds-row'>
      <div className="tds-col-md-12 tds-u-mt1 tds-u-flex-end">
        <TdsButton text='Remove' variant='danger' size='xs' onClick={onRemoveChainItemClick} />
      </div>
    </div>
    <div className="tds-row ">
      <div className="tds-col-md-3">
        <TypeDropDown onItemTypeChange={onItemTypeChange} dataFieldName={name} />
      </div>
      <div className="tds-col-md-3">
        <TextField
          label='Op'
          value={chainItem?.behavior}
          labelPosition="outside"
          dataFieldName={`${name}.op`}
        />
      </div>
      <div className="tds-col-md-6">
        <TextField
          label='Behavior'
          value={chainItem?.behavior}
          labelPosition="outside"
          dataFieldName={`${name}.behavior`}
        />
      </div>
    </div>
    <div className="tds-row tds-u-mt2">
      <div className="tds-col-md-3">
        <TextField
          label='Level'
          value={chainItem?.level}
          labelPosition="outside"
          dataFieldName={`${name}.level`}

        />
      </div>
      <div className="tds-col-md-3">
        <TextField
          label='Hold time'
          value={chainItem?.hold_time}
          labelPosition="outside"
          dataFieldName={`${name}.hold_time`}
        />
      </div>
      <div className="tds-col-md-3">
        <TextField
          label='Operation'
          value={chainItem?.operation}
          labelPosition="outside"
          dataFieldName={`${name}.operation`}
        />
      </div>
    </div>
    <div className="tds-row tds-u-mt2">
      <div className="tds-col-md-12">
        <TextField
          label='Source *'
          value={chainItem?.source}
          labelPosition="outside"
          dataFieldName={`${name}.source`}
          required={true}
        />
      </div>
    </div>
  </div>;
}

export default TypeDyadic