import "./FormSection.scss"
import { observer } from "mobx-react";
import { TdsAccordion, TdsAccordionItem, TdsDivider } from "@scania/tegel-react";
import Histogram from "../Histogram/Histogram";
import Trigger from "../Trigger/Trigger";
import TimeSeries from "../TimeSeries/TimeSeries";
import Gauge from "../Gauge/Gauge";
import { VARIABLE_TYPES } from "../../../helpers/constants";
import { useLocation } from "react-router-dom";
import { useFormState } from "react-final-form";
import TextField from "../../TextField/TextField";
import GenericFieldsView from "../../VariableDetailsView/GenericFieldsView/GenericFieldsView";
import CommonVariantFieldsEdit from "../CommonVariantFieldsEdit/CommonVariantFieldsEdit";
import GenericFields from "../GenericFields/GenericFields";

type FormSectionProps = {
  selectedVariableBaseDetails?: VariableBase | undefined
  variableUpdateMode: string
  isVariableUpdateMode: boolean
}

const FormSection = ({ selectedVariableBaseDetails, variableUpdateMode, isVariableUpdateMode }: FormSectionProps) => {
  const { pathname } = useLocation();
  const { values } = useFormState()
  // const { batch,resetFieldState } = useForm();
  const mode = pathname.toLowerCase().endsWith('create') ? "create" : (isVariableUpdateMode ? "update" : "edit");
  let variableType = values.type


  return <div className={`tds-container-fluid tds-background-grey-50 tds-u-p1 tds-u-pt2 tds-u-mt1 form-container ${mode}`}>

    {/* Base Variable form section */}
    <TdsAccordion className="tds-u-mt0">
      <TdsAccordionItem paddingReset expanded>
        <div slot="header" className="tds-headline-04">Base Variable</div>
        {mode === 'create' ? <GenericFields selectedVariableBaseDetails={selectedVariableBaseDetails} mode={mode} />
          : <GenericFieldsView selectedVariableBaseDetails={selectedVariableBaseDetails} />}
      </TdsAccordionItem>
      <div className="divider-width">
        <TdsDivider orientation="horizontal"></TdsDivider>
      </div>
    </TdsAccordion>

    {/* Variant form section */}
    <div className="tds-row tds-u-mt3">
      <div className="tds-headline-04 tds-u-ml2">{mode === 'create' ? "Default variant" : "Variant"}</div>
    </div>

    {/* Variant name -  part of Variant */}
    <div className="tds-row tds-u-mt3">
      <div className="tds-col-sm-6">
        <TextField
          type="text"
          size="md"
          modeVariant="secondary"
          placeholder="Enter a variant name "
          label="Variant name *"
          labelPosition="outside"
          dataFieldName="varian_name"
          required={true}
        />
      </div>
    </div>

    {/* Variant edit compnents for each variable type */}
    {variableType === VARIABLE_TYPES.GAUGE && <Gauge />}
    {variableType === VARIABLE_TYPES.TRIGGER && <Trigger />}
    {variableType === VARIABLE_TYPES.HISTOGRAM && <Histogram />}
    {variableType === VARIABLE_TYPES.TIME_SERIES && <TimeSeries />}
    {!variableType && <div>Variable type information missing</div>}

    <CommonVariantFieldsEdit variableData={selectedVariableBaseDetails}></CommonVariantFieldsEdit>
  </div>
}

export default observer(FormSection)

