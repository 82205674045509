import { observer } from "mobx-react";
import { TdsAccordion, TdsAccordionItem, TdsDivider, TdsTextField } from "@scania/tegel-react";
import GenericFieldsView from "../GenericFieldsView/GenericFieldsView";
import './FormSectionView.scss'
import GuageView from "../GuageView/GuageView";
import TriggerView from "../TriggerView/TriggerView";
import HistogramView from "../HistogramView/HistogramView";
import TimeSeriesView from "../TimeSeriesView/TimeSeriesView";
import { VARIABLE_TYPES } from "../../../helpers/constants";
import CommonVariantFieldsView from "../CommonVariantFieldsView/CommonVariantFieldsView";

type FormSectionViewProps = {
  selectedVariableBaseDetails: VariableBase
}

const FormSectionView = ({ selectedVariableBaseDetails }: FormSectionViewProps) => {

  return <div className={`tds-container-fluid tds-background-grey-50 tds-u-p1 tds-u-pt2 tds-u-mt1 form-container read`}>

    {/* Base Variable form section */}
    <TdsAccordion className="tds-u-mt0">
      <TdsAccordionItem paddingReset >
        <div slot="header" className="tds-headline-04">Base Variable</div>
        <GenericFieldsView selectedVariableBaseDetails={selectedVariableBaseDetails} />
      </TdsAccordionItem>
      <div className="divider-width">
        <TdsDivider orientation="horizontal"></TdsDivider>
      </div>
    </TdsAccordion>

    {/* Variant form section */}
    <div className="tds-row tds-u-mt3">
      <div className="tds-headline-04 tds-u-ml2">Variant</div>
    </div>

    {/*  TODO: need to work on the part from here onwards  */}
    {/* Variant name - part of variant */}
    <div className='tds-row tds-u-mt3 tds-u-mb3'>
      <div className='tds-col-sm-4'>
        <TdsTextField
          type="text"
          label="Variant name *"
          labelPosition="outside"
          size="md"
          placeholder="Enter the a suitable variant name"
          modeVariant='secondary'
          readOnly={true}
          // TODO: need to figure out the appropriate way to get the variant_name from variantBase object structure. 
          value={selectedVariableBaseDetails?.variant_name}
        />
      </div>
    </div>

    {/* Variant View compnents for each variable type */}
    {selectedVariableBaseDetails?.type === VARIABLE_TYPES.GAUGE && <GuageView variableData={selectedVariableBaseDetails.variant_version} ></GuageView>}
    {selectedVariableBaseDetails?.type === VARIABLE_TYPES.TRIGGER && <TriggerView variableData={selectedVariableBaseDetails.variant_version} ></TriggerView>}
    {selectedVariableBaseDetails?.type === VARIABLE_TYPES.HISTOGRAM && <HistogramView variableData={selectedVariableBaseDetails.variant_version} ></HistogramView>}
    {selectedVariableBaseDetails?.type === VARIABLE_TYPES.TIME_SERIES && <TimeSeriesView variableData={selectedVariableBaseDetails.variant_version}></TimeSeriesView>}
    {!selectedVariableBaseDetails?.type && <div>Variable type information missing</div>}

    {/* common Variant components except the Variant name */}
    <CommonVariantFieldsView variableData={selectedVariableBaseDetails.variant_version}></CommonVariantFieldsView>

  </div>
}

export default observer(FormSectionView)
