const isNotUpdateable = (mode: string, fieldName: string) => {

    if (mode === 'edit' && fieldName === 'Variable type') {
        return true
    }
    else if (mode === 'create' || mode === 'edit') {
        return false
    }
    if (mode === 'update') {
        if (fieldName === 'Variable type' || fieldName === 'Variable name') {
            return true
        }
        else return false
    }
    return true

}

export { isNotUpdateable }