
import { TdsTextField } from '@scania/tegel-react';
import { observer } from 'mobx-react';
import './TriggerView.scss';

interface TriggerViewProps {
    variableData?: VariableVariantVersionDetail
}
const TriggerView = ({ variableData }: TriggerViewProps) => {
    return (
        <>
            <div className="tds-row tds-u-mt2">
                <div className="tds-col-sm-4 tds-col-xs-4">
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Unit"
                        label-position="outside"
                        value={variableData?.unit}
                        readOnly={true}
                        placeholder='Enter unit of measurement'
                    />
                </div>
                <div className="tds-col-sm-4 tds-col-xs-4">
                    {/* //TODO: Currently, the API does not provide any data or at least an attribute. Once the API data is received, it needs to be implemented properly */}
                    <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Mode *"
                        labelPosition="outside"
                        readOnly={true}
                        value={variableData?.mode}>
                    </TdsTextField>
                </div>
                <div className="tds-col-sm-4 tds-col-xs-4">
                    {/* TODO: Currently, the trigger related to this field is available in API 
                     and populate this attribute ecause of that,But  we need to get a final decision from the API team on whether to keep this field or not. */}

                    {/* <TdsTextField
                        type="text"
                        size="md"
                        state="default"
                        modeVariant="secondary"
                        label="Persist"
                        labelPosition="outside"
                        readOnly={true}
                        value={variableData?.persist === '1' ? "True" : "False"}>
                    </TdsTextField> */}

                    {/* TODO: The CanLog display component is not developed yet..  
                    Placeholder for the component (Also need to check with API team the structure of CanLog )   */}

                    
                </div>
            </div>
        </>
    );
};

export default observer(TriggerView);