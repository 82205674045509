export const ALL_FIELDS_ARRAY = ["id" , "name" , "version" , "description" , "status" , "type" , "scope" , "persist" , "boundaries" , "unit" , "minimum_cdc_version" , "current_status_name" , "rfms_name" , "value_names" , "chain"]

export const VARIABLE_STATUS = {
  PUBLISHED: "Published",
  TEST: "Test", // This status is now Deprecated, Please use Verified
  DEPRECATED: "Deprecated",
  CREATED: "Created",
  VERIFIED:"Verified"
}

export const VARIABLE_UPDATE_MODES = {
  WithVersion: 'Update with version',
  WithoutVersion: 'Update w/o version'
}

export const VARIABLE_TYPES = {
  GAUGE: "gauge",
  TRIGGER: "trigger",
  CLASS: "class",
  HISTOGRAM: "class",
  TIME_SERIES: "time_series"
}

export const VARIABLE_ACTIONS = {
  EDIT : "Edit",
  ADD_VARIANT: "AddVariant"
}