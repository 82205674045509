import React from 'react';
import { TdsButton } from '@scania/tegel-react';
import TypeDropDown from './TypeDropDown';
import { ChainEditPropType } from './types';
import GenericDropdown from '../../../GenericDropdown/GenericDropdown';
import TextField from '../../../TextField/TextField';

const TypeAccumulating = ({ chainItem, onRemoveChainItemClick, onItemTypeChange, onAttributeValueChange, index, name }: ChainEditPropType) => {
  return <div className='tds-container-fluid tds-u-p1'>
    <div className='tds-row'>
      <div className="tds-col-md-12 tds-u-mt1 tds-u-flex-end">
        <TdsButton text='Remove' variant='danger' size='xs' onClick={onRemoveChainItemClick} />
      </div>
    </div>
    <div className="tds-row ">
      <div className="tds-col-md-3">
        <TypeDropDown onItemTypeChange={onItemTypeChange} dataFieldName={name} />
      </div>
      <div className="tds-col-md-3">
        <GenericDropdown
          dataFieldName={`${name}.reset_on_false`}
          label='Reset on False'
          labelPosition='outside'
          placeholder='Select option'
          size='md'
          defaultValue={chainItem?.reset_on_false}
          helper='' onTdsChange={(e) => onAttributeValueChange && onAttributeValueChange("", e.detail.value)}
          listData={[{ name: "No", value: "0" }, { name: "Yes", value: "1" }]} />
      </div>
      <div className="tds-col-md-3">
        <TextField
          label='Source *'
          value={chainItem?.source}
          labelPosition="outside"
          dataFieldName={`${name}.source`}
          required={true}
        />
      </div>
    </div>
  </div>;
}

export default TypeAccumulating