import { TdsAccordion, TdsAccordionItem } from "@scania/tegel-react"
import { observer } from "mobx-react";
import "./CommonVariantFieldsEdit.scss"
import ConditionsEdit from "../ConditionsEdit/ConditionsEdit";
import TextField from "../../TextField/TextField";
import ChainEditWrapper from "../ChainEdit/ChainEditWrapper";


interface CommonVariantFieldsEditProps {
    variableData?: VariableBase
}

const CommonVariantFieldsEdit = ({ variableData }: CommonVariantFieldsEditProps) => {
    return (
        <>
            <div className="tds-row tds-u-mt3 tds-col-sm-4">
                <TextField
                    type="text"
                    size="md"
                    modeVariant="secondary"
                    placeholder="Enter the minimum CDC version required"
                    label="Minimum CDC version"
                    labelPosition="outside"
                    dataFieldName="minimum_cdc_version"
                />
            </div>
            <TdsAccordion className="tds-u-mt3">
                <TdsAccordionItem header="Chain *" expanded paddingReset>
                    <ChainEditWrapper />
                </TdsAccordionItem>
                <TdsAccordionItem header="Conditions *" expanded paddingReset>
                    <ConditionsEdit />
                </TdsAccordionItem>
            </TdsAccordion>
        </>
    );
}

export default observer(CommonVariantFieldsEdit);