import { persistDropdownList } from '../../../helpers/genericDropdownList';
import GenericDropdown from '../../GenericDropdown/GenericDropdown';
import { observer } from 'mobx-react';
import './Histogram.scss';
import TextField from '../../TextField/TextField';

const Histogram = () => {
    return (<>
        <div className='tds-row tds-u-mt3'>
            <div className='tds-col-sm-4'>
                <TextField
                    label="Unit *" size="md"
                    label-position="outside"
                    placeholder='Enter unit of measurement'
                    modeVariant="secondary"
                    dataFieldName="unit"
                    required={true} />

            </div>
            <div className='tds-col-sm-4'>
                <GenericDropdown
                    label="Persist"
                    size="md"
                    labelPosition="outside"
                    placeholder='Select a persist value'
                    modeVariant="secondary"
                    listData={persistDropdownList}
                    dataFieldName='persist'
                />
            </div>
        </div>
        <div className='tds-row tds-u-mt3'>
            <div className='tds-col-sm-12 '>
                <TextField
                    label="Boundaries *"
                    size="md"
                    label-position="outside"
                    placeholder='Please enter your boundary values separated by commas. E.g. -1.1, -0.9, -0.7, -0.5, -0.3, -0.1, 0.1, 0.3, 0.5, 0.7, 0.9, 1.1'
                    modeVariant="secondary"
                    dataFieldName="boundaries"
                    required={true}
                />
            </div>
        </div>
    </>
    );
};

export default observer(Histogram);