
import { useLocation } from "react-router-dom";
import { VARIABLE_STATUS } from "../../helpers/constants";
import { TdsButton } from "@scania/tegel-react";

interface TopSectionButtonsProps {
  variableStatus?: string,
  onUserAction: (userAction: string) => void
  returnToReadOnly: () => void
  onCloseClick: () => void
}
const TopSectionButtons = ({ variableStatus, onUserAction, returnToReadOnly, onCloseClick }: TopSectionButtonsProps) => {
  const { pathname } = useLocation();

  const isReadonly = pathname.toLowerCase().endsWith('view');
  const isEditMode = pathname.toLowerCase().endsWith('edit');

  return (
    <span className="tds-u-gap2 tds-row">
      {/* Published */}
      {isReadonly && variableStatus === VARIABLE_STATUS.PUBLISHED && <>
        <TdsButton variant="secondary" text="Update" size="sm" onClick={() => { onUserAction('edit') }} />
        <TdsButton
          variant="secondary"
          text="Duplicate and Add Variant"
          size="sm"
          onClick={() => {
            onUserAction("DuplicateAddVariant");
          }}
        />
        <TdsButton type="submit" variant="danger" text="Deprecate " size="sm" onClick={() => { onUserAction('Deprecated'); }} />
      </>}

      {/* Test */}
      {isReadonly && variableStatus === VARIABLE_STATUS.VERIFIED && <>
        <TdsButton variant="secondary" text="Edit " size="sm" onClick={() => { onUserAction('edit') }} />
        <TdsButton
          variant="secondary"
          text="Duplicate and Add Variant"
          size="sm"
          onClick={() => {
            onUserAction("DuplicateAddVariant");
          }}
        />
        <TdsButton type="submit" variant="primary" text="Publish" size="sm" onClick={() => { onUserAction('Published'); }} />
      </>}
      {/* Created */}
      {isReadonly && variableStatus === VARIABLE_STATUS.CREATED && <>
        <TdsButton
          variant="secondary"
          text="Edit"
          size="sm"
          onClick={() => {
            onUserAction('edit')
          }}
        />
        <TdsButton
          variant="secondary"
          text="Duplicate and Add Variant"
          size="sm"
          onClick={() => {
            onUserAction("DuplicateAddVariant");
          }}
        />
        <TdsButton
          variant="primary"
          text="Mark as Verified"
          size="sm"
          onClick={() => {
            onUserAction("Verified");
          }}
        />
      </>}
      {isEditMode && <>
        <TdsButton
          variant="secondary"
          text="Cancel"
          size="sm"
          onClick={returnToReadOnly}
        />
        <TdsButton
          variant="primary"
          text="Save"
          size="sm"
          onClick={()=>onUserAction("Save")}
        />  
      </>}

    </span>
  )
}

export default TopSectionButtons