import { TdsAccordion, TdsAccordionItem, TdsDivider, TdsTextField } from "@scania/tegel-react"
import { observer } from "mobx-react";
import "./CommonVariantFieldsView.scss"
import { getDisplayValueForTextFields } from "../../../helpers/getDisplayValue";
import { useContext } from "react";
import { UserActionContext } from "../../../AppContext";
import ChainDisplay from "../ChainDisplay/ChainDisplay";
import ConditionsDisplay from "../ConditionsDisplay/ConditionsDisplay";


interface CommonVariantFieldsViewProps {
    variableData?: VariableVariantVersionDetail
}

const CommonVariantFieldsView = ({ variableData }: CommonVariantFieldsViewProps) => {

    const conditionDisplayDummyData: any = {
        "description": "Temperature exceeds threshold",
        "alternatives": [
            {
                "no": 1,
                "description": "Check if temperature exists",
                "tests": [
                    {
                        "test": "test_exist",
                        "type": "fpc1",
                        "instance": "A",
                        "description": "Ensure temperature reading is present"
                    }
                ]
            }
        ]
    };
    const { action } = useContext(UserActionContext);
    return (
        <>
            <div className="tds-row tds-u-mt3 tds-col-sm-6">
                <TdsTextField
                    type="text"
                    size="md"
                    state="default"
                    modeVariant="secondary"
                    placeholder="Enter the minimum CDC version required"
                    label="Minimum CDC version"
                    labelPosition="outside"
                    readOnly={true}
                    value={getDisplayValueForTextFields(variableData?.minimum_cdc_version, action)}
                />
            </div>
            <TdsAccordion className="tds-u-mt3">
                <TdsAccordionItem paddingReset expanded>
                    <div slot="header" className="tds-headline-06">Chain *</div>
                    <ChainDisplay chainData={variableData?.chain} />
                </TdsAccordionItem>
                <div className="divider-width">
                    <TdsDivider orientation="horizontal"></TdsDivider>
                </div>
            </TdsAccordion>
            <TdsAccordion className="tds-u-mt3">
                <TdsAccordionItem paddingReset expanded>
                    <div slot="header" className="tds-headline-06">Conditions *</div>
                    <ConditionsDisplay conditionsData={conditionDisplayDummyData} />
                </TdsAccordionItem>
                <div className="divider-width">
                    <TdsDivider orientation="horizontal"></TdsDivider>
                </div>
            </TdsAccordion>
        </>
    );
}

export default observer(CommonVariantFieldsView);