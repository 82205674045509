import React from "react";
import "./VariableVersionIcon.scss";

const VariableVersionIcon = ({ version,bgCssClass }: { version: string, bgCssClass?:string }) => {
    return (
        <>
            <div className={`versionIcon ${bgCssClass ? bgCssClass : "bg-green" } `}>
                {version}
            </div>
        </>
    );
}

export default VariableVersionIcon;