import { persistDropdownList, shouldRemoveRedundatSamplesDropdownList } from '../../../helpers/genericDropdownList';
import { observer } from "mobx-react";
import GenericDropdown from "../../GenericDropdown/GenericDropdown"
import "./TimeSeries.scss"
import TextField from "../../TextField/TextField"

const TimeSeries = () => {
    return (
        <>
            <div className='tds-row tds-u-mt3'>
                <div className='tds-col-sm-4'>
                    <TextField
                        label="Unit"
                        size="md"
                        label-position="outside"
                        placeholder="Enter unit of measurement"
                        modeVariant="secondary"
                        dataFieldName="unit"
                    />
                </div>
                <div className='tds-col-sm-4'>
                    <GenericDropdown
                        label="Persist *"
                        size="md"
                        labelPosition="outside"
                        placeholder="Select a persist value"
                        modeVariant="secondary"
                        listData={persistDropdownList}
                        dataFieldName="persist"
                    />
                </div>
                <div className='tds-col-sm-4'>
                    <GenericDropdown
                        label="Should remove redundant samples"
                        size="md"
                        labelPosition="outside"
                        placeholder="Select a value"
                        // defaultValue={JSON.stringify(variableData?.should_remove_redundant_samples)}
                        modeVariant="secondary"
                        listData={shouldRemoveRedundatSamplesDropdownList}
                        dataFieldName="should_remove_redundant_samples"
                    />
                </div>
            </div>
            <div className='tds-row tds-u-mt3'>
                <div className='tds-col-sm-6'>
                    <TextField
                        type="number"
                        label="Max samples *"
                        size="md"
                        label-position="outside"
                        placeholder="Enter maximum samples value"
                        // value={getDisplayValueForTextFields(variableData?.max_samples, mode)}
                        modeVariant="secondary"
                        dataFieldName="max_samples"
                        required={true}
                    />
                </div>
                <div className='tds-col-sm-6'>
                    <TextField
                        type="number"
                        label="Sample interval ms"
                        size="md"
                        placeholder="Enter sample interval value in ms"
                        label-position="outside"
                        // value={getDisplayValueForTextFields(variableData?.sample_interval_ms, mode)}
                        modeVariant="secondary"
                        dataFieldName="sample_interval_ms"
                    />
                </div>
            </div>
        </>
    );
}

export default observer(TimeSeries)